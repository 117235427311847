import React from 'react'
import { Link } from 'gatsby'

import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import styles from './privacy.module.scss'

const Privacy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <main className={`${styles.root} ${styles.revision}`}>
        <h1 id="privacy-policy">PRIVACY POLICY</h1>
        <p>
          This “<strong>Privacy Policy</strong>” describes how PicCollage (“
          <strong>PicCollage</strong>”, “<strong>we</strong>”, “
          <strong>us</strong>”, or “<strong>our</strong>”) protects your privacy
          when you use PicCollage, which includes the website located at{' '}
          <a
            href="https://piccollage.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            piccollage.com
          </a>{' '}
          and all mobile phone and web applications developed by{' '}
          <Link to="/">Cardinal Blue Software, Inc.</Link> (each, a “
          <strong>PicCollage Service</strong>” and collectively, the “
          <strong>PicCollage Services</strong>”), with the exception of the
          “PicCollage EDU” application, which has a separate privacy policy.
        </p>
        <p>
          PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY ACCESSING OR USING THE
          SERVICE, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS DESCRIBED
          HEREIN AND ALL TERMS AND CONDITIONS INCORPORATED BY REFERENCE. IF YOU
          DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS SET FORTH BELOW, YOU
          MAY NOT USE THE PICCOLLAGE SERVICE.
        </p>
        <p>
          This Privacy Policy applies to information (including personal
          information) collected through the PicCollage Services. For the
          purpose of this Privacy Policy, &quot;personal information&quot; means
          information that identifies you personally, either alone or in
          combination with other information available to us. Personal
          information does not include technical, device, or usage information
          that cannot be used to identify you personally even if we combine it
          with other data about you, nor does it include &quot;aggregate&quot;
          information, which is data we collect about the use of the PicCollage
          Services or about a group or category of services or users, from which
          individual identities or other personal information have been removed.
          This Privacy Policy in no way restricts or limits our collection and
          use of aggregate and non-personal information, and we may share such
          data about our users with third parties for various purposes. This
          Privacy Policy is part of and incorporated into PicCollage&#39;s{' '}
          <Link to="/tos">Terms of Service</Link> (“
          <strong>Terms of Service</strong>”).
        </p>
        <h1 id="data-privacy">DATA PRIVACY</h1>
        <h2 id="our-basis-to-collect-your-personal-information">
          Our Basis to Collect Your Personal Information
        </h2>
        <p>
          You are about to enter into a contract with us where we provide
          PicCollage Services and you agree to our Terms of Service. This is the
          basis we use to process your Personal Information.
        </p>
        <h2 id="your-rights-as-data-subject">Your Rights as Data Subject</h2>
        <p>
          As the data subject, you have the right to grant or decline consent to
          our terms and policies. The following are the rights you can exercise
          regarding your information:
        </p>
        <ol>
          <li>
            <p>
              The right of access As the data subject, you have the right to
              know how your data is being processed and you can also request to
              access your data.
            </p>
          </li>
          <li>
            <p>
              The right to be informed You have the right to be informed by
              PicCollage on the changes we make regarding the collection and
              processing of your information.
            </p>
          </li>
          <li>
            <p>
              The right to rectification As the data subject, you can obtain
              rectification of inaccurate personal data from us in a timely
              manner.
            </p>
          </li>
          <li>
            <p>
              The right to erasure As the data subject, you have the right to
              obtain the erasure of your data under the following circumstances:
            </p>
            <ol>
              <li>
                the personal data are no longer necessary (for example, in the
                case that a user deletes their account);
              </li>
              <li>when you withdraw consent to our terms and policies; and</li>
              <li>when your personal data have been unlawfully processed</li>
            </ol>
          </li>
          <li>
            <p>
              The right to restriction of processing You have the right to
              obtain restriction of our processing of your data when the
              accuracy of the data is contested by you, the data subject.
            </p>
          </li>
          <li>
            <p>
              The right to portability As the data subject, you can request that
              we transfer any of your data held by us to another company.
            </p>
          </li>
          <li>
            <p>
              The right to object As the data subject, you have the right to ask
              us to stop processing your data.
            </p>
          </li>
          <li>
            <p>
              The right not to be subject to automated individual
              decision-making, including profiling.
            </p>
          </li>
        </ol>
        <h2 id="what-data-we-collect--how">What Data We Collect &amp; How</h2>
        <ol>
          <li>
            <p>
              The information we collect from you includes the country you set
              your device to, the language you use on your device, the type and
              version of the operating system of your device, and your device
              model. If you make any in-app purchases, we will also collect the
              product IDs of the items you purchase.
            </p>
          </li>
          <li>
            <p>
              We collect this information from you to improve our app’s overall
              performance and the service we provide as well as to show you ads
              that are personalized for you. We analyze this information with
              tools provided by third party companies. These companies include:
            </p>
            <ol>
              <li>
                <a
                  href="https://developer.yahoo.com/flurry/legal-privacy/terms-service/flurry-analytics-terms-service.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Flurry
                </a>
                ,{' '}
                <a
                  href="https://firebase.google.com/terms/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Firebase
                </a>
                ,{' '}
                <a
                  href="https://www.google.com/analytics/terms/us.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google
                </a>
                , and{' '}
                <a
                  href="https://help.amplitude.com/hc/en-us/articles/206533238-Data-Security-Privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Amplitude
                </a>
              </li>
              <li>
                You can learn more about the information they collect and
                analyze to ensure that PicCollage provides optimum user
                experience by clicking on the links above.
              </li>
            </ol>
          </li>
          <li>
            <p>
              If you connect PicCollage to other social media platforms such as
              Facebook, we will collect further information from you.
            </p>
            <ol>
              <li>
                Facebook Authentication: authentication data as specified in the{' '}
                <a
                  href="https://www.facebook.com/about/privacy/update"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>{' '}
                privacy policy which include username, email, locale, and
                identifier.
              </li>
            </ol>
          </li>
          <li>
            <p>
              We also provide you with personalized ads to show you content
              that’s tailored to you. The ads are provided by the following
              companies:
            </p>
            <ol>
              <li>
                <a
                  href="https://www.inmobi.com/terms-of-service/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  InMobi
                </a>
                ,{' '}
                <a
                  href="https://www.facebook.com/business/gdpr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
                , and{' '}
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google
                </a>
              </li>
              <li>
                Click on the company links above to learn more about the
                information they collect and how they process it to serve you
                personalized content.
              </li>
            </ol>
          </li>
          <li>
            <p>
              To provide users with an efficient way to communicate with
              PicCollage, we use{' '}
              <a
                href="https://www.zendesk.com/company/customers-partners/privacy-and-data-protection/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Zendesk
              </a>
              , a customer service platform, to receive your feedback and offer
              timely responses to any questions you have. Please note we will
              receive your IP address and email address when you try to contact
              us through Zendesk.
            </p>
          </li>
          <li>
            <p>
              Please note PicCollage will collect your username and email
              address when you consent to create an account. Also, your username
              and the information you choose to share will be available to other
              social network users only when you set your account privacy to
              Public.
            </p>
          </li>
          <li>
            <p>
              Cookies - We automatically collect certain information through the
              use of “cookies”. Cookies are small data files that are stored on
              your device by a PicCollage Service. Among other things, the use
              of cookies helps us to improve the PicCollage Services and your
              experience using them. We use cookies to see which areas and
              features of the Service are most popular, to count the number of
              devices accessing the Site, to personalize your experience and to
              remember your preferences. If your browser or device is set not to
              accept cookies or if you reject a cookie, you may not be able to
              access certain features of the PicCollage Service.
            </p>
          </li>
          <li>
            <p>
              With your permission in the application, we may access your
              device’s photo library.
            </p>
          </li>
        </ol>
        <h2 id="device-information">Device Information</h2>
        <p>
          We collect information from and about certain web-connected devices on
          which you use the PicCollage Services (such as mobile phones and
          computers that utilize a version of the iOS that is older than iOS 14)
          and combine this information across different devices. For example, we
          use information collected about your use of the PicCollage Services on
          your phone to better personalize the content (including ads) or
          features you see when you use the PicCollage Services on another
          device, such as your laptop, or to measure whether you took an action
          in response to an ad we showed you on your phone on a different
          device.
        </p>
        <h2 id="how-we-use-your-personal-information">
          How We Use Your Personal Information
        </h2>
        <p>
          We use personal information collected through the PicCollage Services
          with your permission:
        </p>
        <ol>
          <li>to communicate with you or third parties;</li>
          <li>to process your requests and transactions;</li>
          <li>to improve the PicCollage Services;</li>
          <li>to customize the services and/or products we provide to you;</li>
          <li>to assist with our product and service development;</li>
          <li>to perform marketing activities;</li>
          <li>
            to provide relevant advertising such as showing you ads that are
            tailored for you;
          </li>
        </ol>
        <h2 id="with-whom-we-share-your-personal-information">
          With Whom We Share Your Personal Information
        </h2>
        <p>
          Personal information (and non-personal information) collected through
          the PicCollage Services may be shared with companies and organizations
          that perform services on our behalf (for example, companies that
          provide data management or other support services to us such as
          Heroku). We may share your personal information (and non-personal
          information) with third party ads services which include Google,
          Facebook and InMobi to serve you relevant advertising and market our
          products, but we won&#39;t sell your personal information. Also, we
          may disclose the personal information we collect through the
          PicCollage Services when we, in good faith, believe disclosure is
          appropriate to:
        </p>
        <ol>
          <li>
            comply with applicable law (e.g., in response to a valid court order
            or subpoena);
          </li>
          <li>
            prevent or investigate a possible crime, such as fraud or identity
            theft;
          </li>
          <li>
            enforce the Terms of Service or other agreements that govern your
            use of the PicCollage Services and/or any of our other services;
          </li>
          <li>
            to protect the rights, property or safety of us, our users or
            others; or
          </li>
          <li>to protect your vital interests.</li>
        </ol>
        <p>
          If we are going to release your information in response to a legal
          process, our policy is to provide you with reasonable advance notice
          under the circumstances unless we are prohibited from doing so by law
          or court order (e.g., an order under 18U.S.C. § 2705(b)). We may
          disclose your information without giving you prior notice if we
          believe it is necessary to prevent imminent and serious bodily harm to
          a person. Nothing in this Privacy Policy is intended to limit any
          legal objections or defenses you might have to efforts by third
          parties to compel disclosure of your information, including legal
          orders from the government. Your personal information and other data
          collected by us may be transferred to another company that has
          acquired our stock or assets, for example, as a result of a sale,
          merger, reorganization, dissolution or liquidation. If such a transfer
          occurs, the acquiring company&#39;s use of your personal information
          will still be subject to this Privacy Policy.
        </p>
        <h1 id="how-to-modify-or-delete-your-data-history">
          How To Modify or Delete Your Data History
        </h1>
        <p>
          We respect your right to make choices about the ways we collect, use
          and disclose your personal information. You may update or modify your
          information or change your privacy preferences at any time by emailing
          us at{' '}
          <a href="mailto:support@cardinalblue.com">support@cardinalblue.com</a>{' '}
          or, in some instances, via the PicCollage Services. You may opt out of
          receiving promotional communications from us, including, but not
          limited to, promotional emails, by emailing us at{' '}
          <a href="mailto:support@cardinalblue.com">support@cardinalblue.com</a>
          . Transactional service communications (such as messages about your
          registration) are not subject to the foregoing opt-out procedures.
          Requests to disable your account or reset your password should be sent
          to{' '}
          <a href="mailto:support@cardinalblue.com">support@cardinalblue.com</a>{' '}
          for faster processing. Note, though, that we may not accommodate a
          request to change information if we believe the change would violate
          any law or legal requirement or cause the information to be incorrect.
          You may also delete any personal information that you have provided to
          us by emailing us at{' '}
          <a href="mailto:support@cardinalblue.com">support@cardinalblue.com</a>
          . However, we cannot delete your personal information except by also
          deleting your user account. Also, information that others have shared
          about you is not part of your account and will not be deleted. If you
          would like to delete your account to remove all data history, please
          follow these instructions.{' '}
        </p>
        <h2 id="from-the-piccollage-website">From the PicCollage Website </h2>
        <ul>
          <li>Log into your PicCollage account</li>
          <li>Go to the Settings Page</li>
          <li>Scroll to the bottom of the page and select “Delete Account”</li>
          <li>Click “Delete Account”</li>
          <li>
            Follow the instructions to delete all of your data on our server
          </li>
        </ul>
        <h2 id="from-the-piccollage-app-ios-and-android">
          From the PicCollage App (iOS and Android)
        </h2>
        <ul>
          <li>Tap the “=” menu button in the top left corner</li>
          <li>Tap the PicCollage Community button</li>
          <li>
            Turn on the “Social Network” toggle if it is not already active
          </li>
          <li>Log into your PicCollage account</li>
          <li>Tap the Edit Account Info button</li>
          <li>Scroll to the bottom of the page and tap “Delete Account”</li>
          <li>
            Follow the instructions to delete all of your data on our server
          </li>
        </ul>
        <h1 id="data-retention">DATA RETENTION</h1>
        <p>
          We will delete your personal data immediately upon request unless 
          we are required to retain your data for legal reasons or for any of 
          the other reasons listed for disclosure in 
          “With Whom We Share Your Personal Information” above.
        </p>
        <h1 id="security-of-your-personal-information">
          SECURITY OF YOUR PERSONAL INFORMATION
        </h1>
        <p>
          We take reasonable security and organizational measures to protect
          your personal information (including preventing the loss, misuse,
          unauthorized access, disclosure, alteration and destruction of your
          personal information). For example, we use industry standard
          encryption when transferring your data in and out of the PicCollage
          Services. However, we cannot guarantee that unauthorized third parties
          will not be able to defeat our security measures. If you use a
          password on the PicCollage Services, you are responsible for keeping
          it confidential. Do not share it with any other person. If you believe
          your password has been misused or your account compromised, please
          advise us immediately.
        </p>
        <h1 id="a-note-to-california-residents">
          A NOTE TO CALIFORNIA RESIDENTS
        </h1>
        <p>
          If you are a California resident, please see our Terms of Service to
          learn about your rights and access to data under the CCPA.
          Additionally, California Civil Code Section 1798.83 permits you to
          request information regarding the disclosure of your personal
          information (if any) by PicCollage to third parties for the third
          parties&#39; direct marketing purposes. To make this request, please
          email{' '}
          <a href="mailto:support@cardinalblue.com">support@cardinalblue.com</a>{' '}
          or contact us using this address: Cardinal Blue Software, P.O. Box
          390677, Mountain View, CA94039. If you are a California resident under
          the age of 18, and a registered user of any site where this Privacy
          Policy is posted, California Business and Professions Code Section
          22581 permits you to request and obtain removal of content or
          information you have publicly posted. To make such a request, please
          send an email with a detailed description of the specific content or
          information to{' '}
          <a href="mailto:tosupport@cardinalblue.com">
            support@cardinalblue.com
          </a>
          . Please be aware that such a request does not ensure complete or
          comprehensive removal of the content or information you have posted
          and that there may be circumstances in which the law does not require
          or allow removal even if requested.
        </p>
        <h1 id="changes-to-this-privacy-policy">
          CHANGES TO THIS PRIVACY POLICY
        </h1>
        <p>
          We may change this Privacy Policy from time to time. If we make any
          changes to this Privacy Policy that we think materially alter your
          rights, then we will post the latest policy to this site and change
          the “Last Updated” date above. We encourage you to review this Privacy
          Policy whenever you visit the PicCollage Services to understand how
          your personal information is used.
        </p>
        <h1 id="questions">QUESTIONS</h1>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at{' '}
          <a href="mailto:support@cardinalblue.com">support@cardinalblue.com</a>{' '}
        </p>
        <p>Copyright 2021. All rights reserved.</p>
      </main>
    </Layout>
  )
}

export default Privacy
